import { Box, Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ArfPolicy, ArfAccountViolation, ArfDirective, ArfAccountRemediation } from '../../models';
// import { Switch } from './Switch';
import { 
  // useCallback, 
  // useRef, 
  useState 
} from 'react';
import { CollapsibleTableRow } from '../table/CollapsibleTableRow';
import { Table } from '../table/Table';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { useTheme } from '../../styles/useTheme';
import { darkMode } from '../../styles/theme';
import {
  stackoverflowLight,
  stackoverflowDark,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { dump } from 'js-yaml';

export interface Props {
  arfPolicy: ArfPolicy;
  directives: ArfDirective;
  violations: ArfAccountViolation[];
  remediations: ArfAccountRemediation[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

const statusMessages = {
  on: 'Remediate',
  pending: 'Pending',
  off: 'Read Only',
  disabled: 'Disabled',
}

export const PolicyItem: React.FC<Props> = ({
  arfPolicy,
  directives,
  violations,
  remediations,
  open,
  setOpen,
}) => {
  const [switchState, _setSwitchState] = useState<'on' | 'off' | 'pending'>(
    directives.config.remediate ? 'on' : 'off'
  );

  // const timeout = useRef<NodeJS.Timeout | undefined>(undefined);

  // const onChangeSwitch = useCallback(
  //   (_event: React.ChangeEvent<HTMLInputElement>) => {
  //     if (timeout.current !== undefined) {
  //       clearTimeout(timeout.current);
  //     }

  //     if (switchState === 'on' || switchState === 'pending') {
  //       setSwitchState('off');
  //     } else if (switchState === 'off') {
  //       setSwitchState('pending');
  //       timeout.current = setTimeout(() => {
  //         setSwitchState('on');
  //       }, 5000);
  //     }
  //   },
  //   [switchState, setSwitchState]
  // );

  const [theme] = useTheme();

  const statusMsg = statusMessages[switchState];

  return (
    <CollapsibleTableRow open={open} setOpen={setOpen} collapseContent={
      <Box sx={{ padding: '1em', marginBottom: '1em', marginTop: '1em' }} style={{ cursor: 'auto' }} onClick={(e) => e.stopPropagation()}>
        {/* Let's populate this with a policy description once we have that data available via the API */}
        <div id='description' hidden>
          <p>
            Diomedes, often overshadowed by the legendary Achilles in Greek mythology, possessed qualities that arguably made him cooler in many respects. Unlike Achilles, whose wrath and pride often dictated his actions, Diomedes exhibited a balanced blend of bravery, strategic prowess, and humility.
          </p><p>
            Firstly, Diomedes was known for his exceptional courage on the battlefield. He fearlessly engaged in combat even with gods, famously wounding both Ares, the god of war, and Aphrodite, the goddess of love, in the Trojan War. His audacity in challenging divine beings demonstrated not only his bravery but also his belief in his own abilities.
          </p><p>
            Secondly, Diomedes was a strategic genius. He displayed tactical brilliance during key battles, such as his nighttime raid with Odysseus in the Iliad, where they infiltrated the Trojan camp and executed their mission with precision. His ability to think critically and adapt to situations made him a formidable opponent and a respected leader among his peers.
          </p><p>
            Furthermore, Diomedes exhibited a sense of humility and camaraderie that Achilles often lacked. While Achilles was driven by personal glory and vendettas, Diomedes fought for the collective good of his comrades and his homeland. He respected authority and adhered to codes of honor, making him a more relatable and admirable figure among the Greeks.
          </p><p>
            In conclusion, Diomedes' combination of fearlessness, strategic acumen, and humility sets him apart as a character who, while perhaps less celebrated than Achilles, possessed qualities that made him cooler in the eyes of many who value bravery, intellect, and selflessness in leadership and heroism.
          </p>
        </div>
        <p>
          <Table size='small' hover={false}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: 'center' }}>Resources in Violation</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Resource Name</TableCell>
                <TableCell>Regions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {violations.length > 0 ? violations.map((violation) => (
                <TableRow key={violation.resource_name}>
                  <TableCell>{violation.resource_name}</TableCell>
                  <TableCell>
                    {violation.policy_name.includes('s3')
                      ? 'global'
                      : violation.regions.join(', ')}
                  </TableCell>
                </TableRow>
              )) : (
                <TableRow>
                  <TableCell colSpan={2} sx={{textAlign: 'center'}}>No violations found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </p>
        <p>
          <Table size='small' hover={false}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} sx={{ textAlign: 'center' }}>Recently Remediated Resources</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Resource Name</TableCell>
                <TableCell>Regions</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {remediations.length > 0 ? remediations.map((remediation) => (
                <TableRow key={remediation.resource_name}>
                  <TableCell>{remediation.resource_name}</TableCell>
                  <TableCell>
                    {remediation.policy_name.includes('s3')
                      ? 'global'
                      : remediation.regions.join(', ')}
                  </TableCell>
                  <TableCell>
                    {remediation.actions.join(', ')}
                  </TableCell>
                </TableRow>
              )) : (
                <TableRow>
                  <TableCell colSpan={3} sx={{textAlign: 'center'}}>No remediations found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </p>
        <p>
          <SyntaxHighlighter
            onClick={(e: Event) => {
              e.stopPropagation();
            }}
            language="yaml"
            style={
              theme.id == darkMode.id
                ? stackoverflowDark
                : stackoverflowLight
            }
          >
            {dump(arfPolicy)}
          </SyntaxHighlighter>
        </p>
        <p>
          <Button variant='contained' onClick={() => window.open((arfPolicy.body_template.meta as any).notification.link)}>Full Documentation</Button>
        </p>
      </Box>
    }>
      <TableCell>{arfPolicy.name}</TableCell>
      <TableCell>{violations.length}</TableCell>
      <TableCell onClick={(e) => { e.stopPropagation() }}>
        {switchState === 'on' ? 'ON' : 'OFF'}
        {/* <Switch state={switchState} onChange={onChangeSwitch} disabled={true} /> */}
      </TableCell>
      <TableCell>{statusMsg}</TableCell>
    </CollapsibleTableRow>
  );
};
